export const shows = [
  {
    date: "1587686400000",
    show: "On Air Records",
    venue: "Take 5",
    city: "Bristol",
    link: null,
  },
  // {
  //     date: "1584662400000",
  //     show: "TFTD",
  //     venue: "Night Tales",
  //     city: "London",
  //     link: "https://www.residentadvisor.net/events/1379078?fbclid=IwAR3Pdc-eSLCK1xIODV5xV6J9kzGN4YuXxizAqr4ipmMnthDI9Uepxy_I6Ss",
  //   },
  {
    date: "1582675200000",
    show: "Double Vision",
    venue: "Love Inn",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1296449",
  },
  {
    date: "1579132800000",
    show: "Paragon x BOXD",
    venue: "Crofters Rights",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1366773",
  },
  {
    date: "1576800000000",
    show: "Cocoon Bristol",
    venue: "Motion",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1296449",
  },
  {
    date: "1574985600000",
    show: "On Air Records",
    venue: "Take 5",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1348588",
  },

  {
    date: "1569628800000",
    show: "Drumcode Bristol",
    venue: "Motion",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1273412",
  },
  {
    date: "1557014400000",
    show: "Block97",
    venue: "Work Bar",
    city: "London",
    link: "https://www.residentadvisor.net/events/1214445",
  },

  {
    date: "1556928000000",
    show: "YARD",
    venue: "Motion",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1214445",
  },

  {
    date: "1556928000000",
    show: "Parklife House Party",
    venue: "Pennywell Project",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1260198",
  },
  {
    date: "1546383600000",
    show: "In:Motion NYD",
    venue: "Motion",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1178187",
  },

  {
    date: "1524265200000",
    show: "Dusky - 17 Steps",
    venue: "Motion",
    city: "Bristol",
    link: "https://www.residentadvisor.net/events/1044317",
  },
]
