import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Upcoming } from "./gigs-upcoming"
import { shows } from "../djshows"
import Past from "./gigs-past"
import DJImage from "./images/dj"
var moment = require("moment")

const today = moment(new Date()).valueOf()

const pastDates = shows.filter(function(show) {
  return show.date < today
})

const upcomingDates = shows.filter(function(show) {
  return show.date > today
})

const Gigs = ({ siteTitle }) => (
  <div>
    <Upcoming shows={upcomingDates} />
    <DJImage />
    <Past shows={pastDates} />
  </div>
)

export default Gigs
