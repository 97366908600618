import React from "react"
var moment = require("moment")

export const Upcoming = props => (
  <div className="p-4">
    <h3 className="uppercase font-bold text-4xl mb-4"> Upcoming</h3>
    <table class="table-auto">
      <thead>
        <tr>
          <th class="px-2 py-2">Date</th>
          <th class="px-2 py-2">Show</th>
          <th class="px-2 py-2">Venue</th>
          <th class="px-2 py-2">City</th>
          <th class="px-2 py-2">Link</th>
        </tr>
      </thead>
      <tbody>
        {props.shows.map((item, key) => (
          <tr>
            <td class=" px-2 py-2">
              {moment.unix(item.date / 1000).format("Do MMM YY")}
            </td>
            <td class=" px-2 py-2">{item.show}</td>
            <td class=" px-2 py-2">{item.venue}</td>
            <td class=" px-2 py-2">{item.city}</td>
            <td class=" px-2 py-2">
              {item.link != null ? (
                <a href={item.link}>
                  {" "}
                  <img
                    class="w-8 mt-4"
                    src="https://i.postimg.cc/28tJDSgk/ra-logo.png"
                  />{" "}
                </a>
              ) : (
                <p></p>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
